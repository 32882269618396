import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AldiHeader from './aldi-header.png';

const AppNavbar = ({ expand, wording }) => {
  return (
    <div className="bg-aldi-dark-blue">
      <Container className='bg-aldi-dark-blue px-0'>
        <Row className="justify-content-between align-items-center mx-0">
          <Col className="ps-0">
            <img alt="Aldi's Big Walk For Little Kids" src={AldiHeader} className="img-fluid h-100 mxh-8" />
          </Col>
          <Col className="text-center">
            <h4 className="mb-0">{wording}</h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppNavbar;
