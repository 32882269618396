import React, { useEffect, useState } from 'react';
import { getTopUsers } from '../services/api';
import { Col, Container, Row } from 'react-bootstrap';
import AppNavbar from '../components/Navbar';

const TopUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
      const intervalId = setInterval(() => {
      getTopUsers()
      .then(response => setUsers(response.data.top_users))
      .catch(error => console.error('There was an error fetching the users!', error));
    }, 5000);

    getTopUsers()
      .then(response => setUsers(response.data.top_users))
      .catch(error => console.error('There was an error fetching the users!', error));

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <AppNavbar expand="lg" wording="Individual Leaderboard" />
      <Container className="section-content-sm">
        <Row>
          <Col xs="1"></Col>
          <Col xs="7"><h4 className='text-aldi-light-blue'>Individual</h4></Col>
          <Col xs="4"><h4 className='text-aldi-light-blue'>Total Kilometers</h4></Col>
        </Row>
        
        {users.map((user, index) => (
          <Row className={'py-3' + (index === 0 ? '' : ' border-top-aldi-light-blue')} key={index + 1}>
            <Col xs="1" className="text-aldi-light-blue">{index + 1}</Col>
            <Col xs="7">{user.first_name} {user.last_name}</Col>
            <Col xs="4">{(Math.trunc(user.total_km_walked * 100) / 100)}</Col>
          </Row>
        ))}
      </Container>
    </>
  );
};

export default TopUsers;
