import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowToast } from '../services/toast';
import { getAllData } from '../services/api';

const DataDownload = () => {
    const nav = useNavigate();

  useEffect(() => {
    // Function to trigger the download
    const downloadData = () => {
    getAllData().then((response) => {
        const data = response.data.records;

        // Convert the array of objects to CSV format
        const csvRows = [];

        // Get the headers (keys of the first object in the array)
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(',')); // Join the headers with commas

        // Loop over the data to create CSV rows
        for (const row of data) {
          const values = headers.map(header => JSON.stringify(row[header])); // Safely stringify each value
          csvRows.push(values.join(',')); // Join each value with commas
        }

        // Create the CSV content
        const csvContent = csvRows.join('\n'); // Join each row with a newline

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' }); // Specify the MIME type as 'text/csv'
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv'; // Set the desired file name
        a.style.display = 'none'; // Ensure it doesn't affect the layout
        document.body.appendChild(a);
        a.click(); // Trigger the download

        // Clean up the temporary link and revoke the object URL
        a.remove();
        window.URL.revokeObjectURL(url);

        ShowToast("download-success", "SUCCESS", 'Data has been downloaded.');
        //redirect to welcome
        nav('/');
    }).catch((error) => {
        ShowToast("download-error", "ERROR", error.response.data.message);
    });
      
    };

    // Trigger the download when the component is mounted
    downloadData();
  }, [nav]); // Empty dependency array ensures this effect runs only once

  // Render nothing
  return null;
};

export default DataDownload;
