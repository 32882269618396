import React, { useEffect, useState } from 'react';
import { getTopDepartments } from '../services/api';
import { Col, Container, Row } from 'react-bootstrap';
import AppNavbar from '../components/Navbar';

const TopDepartments = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getTopDepartments()
      .then(response => setDepartments(response.data.top_departments))
      .catch(error => console.error('There was an error fetching the departments!', error));
    }, 5000);

    getTopDepartments()
      .then(response => setDepartments(response.data.top_departments))
      .catch(error => console.error('There was an error fetching the departments!', error));
      
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    <AppNavbar expand="lg" wording="Department Leaderboard" />
    <Container className="section-content-sm">
      <Row>
        <Col xs="1"></Col>
        <Col xs="7"><h4 className='text-aldi-light-blue'>Department</h4></Col>
        <Col xs="4"><h4 className='text-aldi-light-blue'>Total Kilometers</h4></Col>
      </Row>
      
        {departments.map((department, index) => (
          <Row className={'py-3' + (index === 0 ? '' : ' border-top-aldi-light-blue')} key={index + 1}>
            <Col xs="1" className="text-aldi-light-blue">{index + 1}</Col>
            <Col xs="7">{department.name}</Col>
            <Col xs="4">{(Math.trunc(department.total_km_walked * 100) / 100)}</Col>
          </Row>
        ))}
    </Container>
    </>
  );
};

export default TopDepartments;
