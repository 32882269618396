import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { loginUser } from '../services/api';
import { Link, useNavigate } from 'react-router-dom';
import { ShowToast } from '../services/toast';
import AppNavbar from '../components/Navbar';

const Login = () => {
  const [email, setEmail] = useState('');
  const nav = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    loginUser({'email_address': email}).then((response) => {
      localStorage.setItem('user_id', response.data.user.id);
      localStorage.setItem('first_name', response.data.user.first_name);
      localStorage.setItem('last_name', response.data.user.last_name);
      nav('/user-update');
    }).catch((error) => {
      ShowToast("login-error", "ERROR", error.response.data.message);
    });
  };

  return (
    <>
      <AppNavbar wording="Track my distance" />
      <Container className='section-content'>
        <Row>
          <Col>
            <h1 className="mb-4">Return Participant</h1>
          </Col>
        </Row>
        <Row className='justify-content-center align-items-center'>
          <Col>
            <Form className="d-block rounded bg-aldi-dark-blue p-5" onSubmit={handleLogin}>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label className='text-aldi-light-blue'>Email Address</Form.Label>
                <Form.Control variant="white" type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>

              <Button className='btn-aldi-orange' type="submit">
                <div className='text-white mb-0'>Login</div>
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className='justify-content-center'>     
          <Col className='mt-4 text-center'>
            <Link to={'/register'}>Don't have an account? Create one now</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
