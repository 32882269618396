import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { updateUser } from '../services/api';
import { ShowToast } from '../services/toast';
import AppNavbar from '../components/Navbar';

const UserUpdate = () => {
  const [kmWalked, setKmWalked] = useState(0);
  const userId = localStorage.getItem('user_id');
  const firstName = localStorage.getItem('first_name');
  const lastName = localStorage.getItem('last_name');
  const nav = useNavigate();

  if(!userId) {
    // not logged in
    localStorage.clear();
    nav('/');
  }
  const handleUpdate = (e) => {
    e.preventDefault();
    updateUser({
      "user_id": userId,
      "km_walked": (Math.trunc(kmWalked * 100) / 100)
    }).then((response) => {
      ShowToast("user-update-success", "SUCCESS", firstName + ' ' + lastName + ' has been updated!');
      localStorage.clear();
      nav('/');
    }).catch((error) => {
      ShowToast("user-update-error", "ERROR", error.response.data.message);
    });;
  };

  return (
    <>
      <AppNavbar wording="Track my distance" />
      <Container className='section-content'>
        <Row>
          <Col>
            <h1 className="mb-4">Welcome, {firstName} {lastName}</h1>
          </Col>
        </Row>
        <Row className='justify-content-center align-items-center'>
          <Col>
            <Form className="d-block rounded bg-aldi-dark-blue p-5" onSubmit={handleUpdate}>
              <Form.Group className="mb-4" controlId="formBasicName">
                <Form.Label className='text-aldi-light-blue'>Distance Walked (to the nearest 0.01km)</Form.Label>
                <Form.Control
                  variant="white"
                  type="text"
                  placeholder="0"
                  value={kmWalked}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (regex.test(input) || input === "") {
                      setKmWalked(input);
                    }
                  }}
                  inputMode="decimal" // Optimizes the keyboard for numeric input with a decimal point on mobile devices
                  pattern="[0-9]*\.?[0-9]*" // Provides a hint for browser validation
                />
              </Form.Group>

              <Button className='btn-aldi-orange' type="submit">
                <div className='text-white mb-0'>Add to Total</div>
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className='justify-content-center'>     
          <Col className='mt-4 text-center'>
            <Link to={'/login'} onClick={() => { localStorage.clear(); }}>Not you? Back to login</Link>
          </Col>
        </Row>
      </Container>
     </>
  );
};

export default UserUpdate;
