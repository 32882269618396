import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { getDepartments, registerUser } from '../services/api';
import { Link, useNavigate } from 'react-router-dom';
import { ShowToast } from '../services/toast';
import AppNavbar from '../components/Navbar';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mainDepId, setMainDepId] = useState('');
  const [mainDeps, setMainDeps] = useState([]);
  const [subDepId, setSubDepId] = useState('');
  const [subDeps, setSubDeps] = useState([]);
  const [subDepList, setSubDepList] = useState([]);
  const nav = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    registerUser({
      "first_name": firstName,
      "last_name": lastName,
      "email_address": email,
      "main_dep_id": mainDepId,
      "sub_dep_id": subDepId
    }).then((response) => {
      localStorage.setItem('user_id', response.data.user.id);
      localStorage.setItem('first_name', response.data.user.first_name);
      localStorage.setItem('last_name', response.data.user.last_name);
      nav('/user-update');
    }).catch((error) => {
      ShowToast("register-error", "ERROR", error.response.data.message);
    });;
  };

  const changeSubDepList = (selectedMainDepId) => {
    setSubDepId('');

    if(selectedMainDepId === '') {
      setSubDepList([]);
    } else {
      setSubDepList(subDeps.filter((e) => parseInt(e.main_dep_id) === parseInt(selectedMainDepId)));
    }
  };

  useEffect(() => {
    getDepartments().then((response) => {
      setMainDeps(response.data.main_departments);
      setSubDeps(response.data.sub_departments);
    });
  }, []);

  return (
    <>
      <AppNavbar wording="Track my distance" />
      <Container className='section-content'>
        <Row>
          <Col>
            <h1 className="mb-4">New Participant</h1>
          </Col>
        </Row>
        <Row className='justify-content-center align-items-center'>
          <Col>
            <Form className="d-block rounded bg-aldi-dark-blue p-5" onSubmit={handleRegister}>
              <Form.Group controlId="formBasicName">
                <Row>
                  <Col>
                    <Form.Label className='text-aldi-light-blue'>First Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  </Col>
                  <Col>
                    <Form.Label className='text-aldi-light-blue'>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Enter last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mt-4" controlId="formBasicEmail">
                <Row>
                  <Col>
                    <Form.Label className='text-aldi-light-blue'>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mt-4" controlId="formMainDepartment">
                <Row>
                  <Col>
                    <Form.Label className='text-aldi-light-blue'>Department</Form.Label>
                    <Form.Select onChange={(e) => {setMainDepId(e.target.value); changeSubDepList(e.target.value);}}>
                      <option value="">Please select</option>
                      {mainDeps.map((dep) => (
                        <option key={`main-` + dep.id} value={dep.id}>{dep.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className={mainDepId === '' ? 'd-none' : 'mt-2'} controlId="formSubDepartment">
                <Row>
                  <Col>
                    <Form.Select disabled={mainDepId === ''} onChange={(e) => {setSubDepId(e.target.value);}}>
                      <option value="">Please select</option>
                      {subDepList.map((dep) => (
                        <option key={`sub-` + dep.id} value={dep.id}>{dep.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>

              <Button className='btn-aldi-orange mt-4' type="submit">
                <div className='text-white mb-0'>Create Account</div>
              </Button>

            </Form>
          </Col>
        </Row>
        <Row className='justify-content-center'>     
          <Col className='mt-4 text-center'>
            <Link to={'/login'}>Already have an account? Login</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
