import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppNavbar from '../components/Navbar';

const Welcome = () => {
  return (
    <>
      <AppNavbar wording="Track my distance" />
      <Container className='section-content'>
        <Row>
          <Col>
            <h1 className="mb-4">Welcome</h1>
          </Col>
        </Row>
        <Row className='justify-content-center align-items-center row-cols-1 row-cols-md-2'>
          <Col className="align-self-stretch">
            <div className='rounded text-center bg-aldi-dark-blue py-5 px-4'>
              <h2 className='mb-0 text-center text-aldi-light-blue'>Return Participant</h2>
              <h5 className='my-4 text-center text-aldi-white'>I have already created an account</h5>
              <Link to={'/login'} className="btn btn-aldi-orange w-100" role="button">
                <div className='text-white mb-0'>Login</div>
              </Link>
            </div>
          </Col>
          <Col className='mt-4 mt-md-0 align-self-stretch d-flex'>
            <div className='rounded text-center bg-aldi-dark-blue py-5 px-4 w-100'>
              <h2 className='mb-0 text-center text-aldi-light-blue'>New Participant</h2>
              <h5 className='my-4 text-center text-aldi-white'>I don't have an account yet</h5>
              <Link to={'/register'} className="btn btn-aldi-orange w-100" role="button">
                <div className='text-white mb-0'>Get Started</div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
    
  );
};

export default Welcome;
