import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './pages/Welcome';
import Login from './pages/Login';
import Register from './pages/Register';
import TopUsers from './pages/TopUsers';
import TopDepartments from './pages/TopDepartments';
import UserUpdate from './pages/UserUpdate';
import DataDownload from './pages/DataDownload';

const App = () => {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/top-users" element={<TopUsers />} />
        <Route path="/top-departments" element={<TopDepartments />} />
        <Route path="/user-update" element={<UserUpdate />} />
        <Route path="/download" element={<DataDownload />} />
      </Routes>
    </Router>
    </>
  );
};

export default App;
