import { toast } from "react-toastify";

export const ShowToast = (toastId, toastType, toastDescription) => {

    switch (toastType) {
        case "SUCCESS":
            toast.success(toastDescription, {
                toastId: toastId,
                autoClose: 3000,
                position: 'bottom-right',
            });
            break;
        case "ERROR":
            toast.error(toastDescription, {
                toastId: toastId,
                autoClose: 3000,
                position: 'bottom-right',
            });
            break;
        case "WARNING":
            toast.warning(toastDescription, {
                toastId: toastId,
                autoClose: 3000,
                position: 'bottom-right',
            });
            break;
        case "INFO":
            toast.info(toastDescription, {
                toastId: toastId,
                autoClose: 3000,
                position: 'bottom-right',
            });
            break;
    }
}
